import { app, analytics, db } from "../firebaseConfig";
import {
  getFirestore,
  collection,
  query,
  orderBy,
  getDocs,
  where,
  doc,
  getDoc,
  onSnapshot,
  addDoc,
  updateDoc,
  serverTimestamp
} from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { Platform } from "react-native";
import axios from 'axios';


import AsyncStorage from "@react-native-async-storage/async-storage";
import { formatTimestamp } from "../utils/formatters";

/**
 * Recupera la información completa del usuario loggeado
 */
export const getUserFromStorage = async () => {
  try {
    const user = await AsyncStorage.getItem("user");
    console.log("USERR FOUND", JSON.parse(user));
    const userData = user ? JSON.parse(user).user : null;
    if (!userData || !userData.id) {
      throw new Error("Información del usuario no válida");
    }
    return userData;
  } catch (error) {
    console.error(
      "Error al obtener datos del usuario de almacenamiento:",
      error
    );
    throw new Error("Información del usuario no encontrada");
  }
};

/**
 * Recupera la información completa de los chats
 */
export const getChatsFromUserId = async (setChats) => {
  try {
    const user = await getUserFromStorage(); // Obtiene el usuario loggeado

    // Aquí creas la referencia de la colección chats en Firestore
    const chatsRef = collection(db, "chats");

    // Preparamos la consulta de chats, ordenada por el último mensaje
    const q = query(chatsRef, orderBy("lastMessage.timestamp", "desc"));

    // Usamos onSnapshot para escuchar los cambios en tiempo real
    const unsubscribe = onSnapshot(q, async (querySnapshot) => {
      const chats = [];

      // Recorre los documentos de la colección
      for (const documentSnapshot of querySnapshot.docs) {
        const chatId = documentSnapshot.id;
        const chatData = documentSnapshot.data();

        // Verificar si el usuario está en los participantes
        const participantsRef = collection(db, "chats", chatId, "participants");
        const participantsSnapshot = await getDocs(participantsRef);
        const participants = participantsSnapshot.docs.map((doc) => doc.data());
        const userIsParticipant = participants.some(
          (participant) => participant.userId === user.id
        );
        const lastAccess = participants.find(
          (participant) => participant.userId === 551
        );

        if (!userIsParticipant) {
          continue; // Si el usuario no es participante, omitir este chat
        }

        // Verificar si el chat es de tipo "CN" y tiene solo un mensaje "placeholder"
        if (chatData.type === "CN") {
          const messagesRef = collection(db, "chats", chatId, "messages");
          const messagesSnapshot = await getDocs(messagesRef);
          const messageDocs = messagesSnapshot.docs;
          const onlyHasPlaceholder =
            messageDocs.length === 1 && messageDocs[0].id === "placeholder";

          if (onlyHasPlaceholder) {
            continue; // Omitir el chat si solo tiene un documento "placeholder"
          }
        }

        let otherParticipant = null;
        if (chatData.type === "CN") {
          // Si es un chat con una sola persona, encontrar al otro participante
          otherParticipant = participants.find(
            (participant) => participant.userId !== user.id
          );
        }

        // Formatear timestamp
        let formattedTimestamp = "";
        if (chatData.lastMessage?.timestamp) {
          const timestamp = chatData.lastMessage.timestamp;
          formattedTimestamp = formatTimestamp(
            timestamp.seconds * 1000 +
              Math.floor(timestamp.nanoseconds / 1000000)
          );
        }

        // Determinar texto del último mensaje
        let lastMessageText = chatData.lastMessage?.message || "";

        // Para chat "CN" (chat individual)
        if (chatData.type === "CN") {
          if (chatData.lastMessage?.senderId === user.id) {
            lastMessageText = `Tú: ${chatData.lastMessage?.message || ""}`;
          } else {
            lastMessageText = `${chatData.lastMessage?.message || ""}`;
          }
        } else {
          // Para grupos o chats "CH"
          if (chatData.lastMessage?.senderId === user.id) {
            lastMessageText = `Tú: ${chatData.lastMessage?.message || ""}`;
          } else {
            const sender = participants.find(
              (participant) =>
                participant.userId === chatData.lastMessage?.senderId
            );

            const senderName = sender ? sender.name : "";
            lastMessageText = senderName
              ? `${senderName}: ${chatData.lastMessage?.message || ""}`
              : "";
          }
        }

        // Agregar el chat a la lista si el usuario es participante
        chats.push({
          chatId,
          createdAt: chatData.createdAt.toDate().toISOString(),
          image:
            chatData.type === "CN" && otherParticipant
              ? otherParticipant.imageUrl
              : chatData.image,
          lastMessage: {
            ...chatData.lastMessage,
            messageId: chatData.lastMessage.messageId,
            message: lastMessageText,
            timestamp: formattedTimestamp,
            formTime: chatData.lastMessage.timestamp,
          },
          type: chatData.type,
          name:
            chatData.type === "CN" && otherParticipant
              ? otherParticipant.name
              : chatData.name,
          blocked: chatData.blocked, //OPT
          participants,
          dbChatId: chatData.dbChatId,
          notificationId: chatData.type + chatData.dbChatId,
          loggedUserId: user.id,
          lastAccess: lastAccess ? lastAccess.lastAccess : null
        });
      }

      // Ordenar los chats utilizando la función externa
      const sortedChats = sortChatsByTimestamp(chats);

      // Establecer los chats en el estado
      setChats(sortedChats);
    });

    return unsubscribe; // Esto devolverá la función para cancelar la escucha si es necesario
  } catch (error) {
    console.error("Error al obtener los chats:", error);
  }
};

// Función para ordenar los chats por fecha
const sortChatsByTimestamp = (chats) => {
  return chats.sort((a, b) => {
    const hasLastMessageA = !!a.lastMessage?.timestamp;
    const hasLastMessageB = !!b.lastMessage?.timestamp;

    if (hasLastMessageA && !hasLastMessageB) {
      return -1;
    }
    if (!hasLastMessageA && hasLastMessageB) {
      return 1;
    }

    const timestampA = hasLastMessageA
      ? new Date(a.lastMessage.timestamp).getTime()
      : new Date(a.createdAt).getTime();
    const timestampB = hasLastMessageB
      ? new Date(b.lastMessage.timestamp).getTime()
      : new Date(b.createdAt).getTime();

    return timestampB - timestampA;
  });
};

/**
 * Recupera los mensajes de la colección interna del chat y escucha los cambios
 * @param {string} chatId - El ID del chat cuyo mensaje se desea obtener
 * @param {function} setMessages - Función para actualizar el estado de los mensajes
 */
export const getMessagesFromChat = (chatId, setMessages) => {
  const messagesRef = collection(db, "chats", chatId, "messages");
  const messagesQuery = query(messagesRef, orderBy("timestamp", "asc"));

  const unsubscribe = onSnapshot(messagesQuery, (querySnapshot) => {
    const messages = [];

    querySnapshot.forEach((documentSnapshot) => {
      const messageData = documentSnapshot.data();
      messages.push({
        ...messageData,
        timestamp: messageData.timestamp?.toDate(),
      });
    });

    // Actualiza el estado con los mensajes obtenidos
    setMessages(messages);
  });

  return unsubscribe; // Devuelve la función para cancelar la suscripción
};

/**
 * Recupera los mensajes del chat correspondiente al dbChatId y escucha los cambios
 * @param {number} dbChatId - El valor único del campo dbChatId del chat cuyo mensaje se desea obtener
 * @param {function} setMessages - Función para actualizar el estado de los mensajes
 */
export const getMessagesFromChatByDbChatId = async (dbChatId, setMessages) => {
  const chatsRef = collection(db, "chats");
  const dbChatIdNumber = Number(dbChatId);
  try {
    // Buscar el chat por su dbChatId
    const chatQuery = query(chatsRef, where("dbChatId", "==", dbChatIdNumber));
    const chatSnapshot = await getDocs(chatQuery);

    if (chatSnapshot.empty) {
      console.error(
        "No se encontró ningún chat con el dbChatId proporcionado."
      );
      return null;
    }

    // Asumimos que el dbChatId es único y tomamos el primer resultado
    const chatDoc = chatSnapshot.docs[0];
    const chatId = chatDoc.id;

    // Referencia a los mensajes del chat encontrado
    const messagesRef = collection(db, "chats", chatId, "messages");
    const messagesQuery = query(messagesRef, orderBy("timestamp", "asc"));

    // Suscribirse a los mensajes y actualizar el estado
    const unsubscribe = onSnapshot(messagesQuery, (querySnapshot) => {
      const messages = [];

      querySnapshot.forEach((documentSnapshot) => {
        const messageData = documentSnapshot.data();
        messages.push({
          ...messageData,
          timestamp: messageData.timestamp?.toDate(),
        });
      });

      setMessages(messages);
    });

    return unsubscribe; // Devuelve la función para cancelar la suscripción
  } catch (error) {
    console.error("Error al obtener los mensajes del chat:", error);
    return null;
  }
};

/**
 * Recupera los datos del chat mediante dbChatId y escucha cambios
 * @param {number} dbChatId - El valor único del campo dbChatId del chat cuyo chat se quiere obtener
 */
export const getChatDataFromId = async (dbChatId) => {
  const user = await getUserFromStorage();
  const chatRef = collection(db, "chats");

  const chatQuery = query(chatRef, where("dbChatId", "==", parseInt(dbChatId)));
  const querySnapshot = await getDocs(chatQuery);

  if (querySnapshot.empty) {
    throw new Error(`No chat found with dbChatId ${dbChatId}`);
  }

  const chatDoc = querySnapshot.docs[0];
  const chatData = chatDoc.data();
  const chatId = chatDoc.id;

  // Obtener los participantes del chat
  const participantsRef = collection(db, "chats", chatId, "participants");
  const participantsSnapshot = await getDocs(participantsRef);
  const participants = participantsSnapshot.docs.map((doc) => doc.data());

  // Verificar si el usuario es participante del chat
  const userIsParticipant = participants.some(
    (participant) => participant.userId === user.id
  );

  if (!userIsParticipant) {
    throw new Error(
      `User is not a participant of the chat with dbChatId ${dbChatId}`
    );
  }

  // Obtener información adicional si es un chat individual (tipo CN)
  let otherParticipant = null;
  if (chatData.type === "CN") {
    otherParticipant = participants.find(
      (participant) => participant.userId !== user.id
    );
  }

  // Formatear el timestamp del último mensaje
  let formattedTimestamp = "";
  if (chatData.lastMessage?.timestamp) {
    const timestamp = chatData.lastMessage.timestamp;
    formattedTimestamp = formatTimestamp(
      timestamp.seconds * 1000 + Math.floor(timestamp.nanoseconds / 1000000)
    );
  }

  // Formatear el texto del último mensaje
  let lastMessageText = chatData.lastMessage?.message || "";
  if (chatData.type === "CN") {
    if (chatData.lastMessage?.senderId === user.id) {
      lastMessageText = `Tú: ${chatData.lastMessage.message}`;
    }
  } else {
    if (chatData.lastMessage?.senderId === user.id) {
      lastMessageText = `Tú: ${chatData.lastMessage.message}`;
    } else {
      const sender = participants.find(
        (participant) => participant.userId === chatData.lastMessage?.senderId
      );
      lastMessageText = sender
        ? `${sender.name}: ${chatData.lastMessage.message}`
        : chatData.lastMessage?.message || "";
    }
  }

  // Retornar los datos del chat con los participantes
  return {
    chatId,
    dbChatId: chatData.dbChatId,
    createdAt: chatData.createdAt.toDate().toISOString(),
    image:
      chatData.type === "CN" && otherParticipant
        ? otherParticipant.imageUrl
        : chatData.image,
    lastMessage: {
      ...chatData.lastMessage,
      message: lastMessageText,
      timestamp: formattedTimestamp,
    },
    type: chatData.type,
    name:
      chatData.type === "CN" && otherParticipant
        ? otherParticipant.name
        : chatData.name,
    blocked: chatData.blocked,
    participants,
    notificationId: chatData.type + chatData.dbChatId,
    loggedUserId: user.id,
  };
};

export const sendMessageToChat = async (chatId, text, file) => {
  try {
    const user = await getUserFromStorage(); // Esta función sigue funcionando igual

    // Si hay archivo, lo sube y obtiene los datos del archivo
    console.log("FILE ", file);
    const fileData = file ? await uploadFileToStorage(file, chatId) : null;
    let fileUrl = fileData?.url || null;
    let fileType = fileData?.fileType || null;
    let fileRef = fileData?.fileRef || null;

    // Crear mensaje con los datos necesarios
    const message = {
      deleted: false,
      file: fileUrl,
      fileRef: fileRef,
      fileName: file?.name || null,
      fileType: fileType,
      senderId: user.id,
      text: text,
      timestamp: serverTimestamp(),
    };

    // Obtener referencia al chat y agregar mensaje a la subcolección
    const chatRef = doc(db, "chats", chatId);
    const chatSnap = await getDoc(chatRef);
    const chatData = chatSnap.data();

    const messageRef = await addDoc(collection(chatRef, "messages"), message);
    const messageId = messageRef.id;

    const messageData = {
      ...message,
      messageId: messageId,
    };

    // Actualizar el mensaje con el ID generado
    await updateDoc(messageRef, messageData);

    // Actualizar la última información del mensaje en el chat
    const lastMessageData = {
      message: text,
      messageType: fileType || "text",
      recipientId: "", // Este campo parece vacío, si se necesita agregar más lógica, se puede agregar aquí
      senderId: user.id,
      timestamp: message.timestamp,
      messageId: messageId,
    };

    await updateDoc(chatRef, { lastMessage: lastMessageData });

    // Enviar notificación de nuevo mensaje
    await notifyNewMessage(`${chatData.type}${chatData.dbChatId}`, user.id, message.text);

    console.log("Mensaje enviado correctamente y lastMessage actualizado");
  } catch (error) {
    console.error("Error al enviar el mensaje: ", error);
  }
};

/**
 * Sube un archivo a Firebase Storage y retorna la URL y el tipo del archivo
 * @param {File} file - Archivo a subir
 * @param {string} chatId - ID del chat (opcional para organizar en carpetas)
 * @returns {Promise<{url: string, fileType: string}>} - URL de descarga y tipo de archivo
 */
const uploadFileToStorage = async (file, chatId) => {
  if (!file) return null;

  try {
    console.log("FILEEE ", file);
    const storage = getStorage(); // Obtén la referencia de Firebase Storage
    const storageRef = ref(storage); // Referencia general al storage
    const fileId = Date.now().toString();


    // Determinar el tipo de archivo (imagen, video, audio, etc.)
    const fileType = file.type.startsWith('image/')
      ? 'images'
      : file.type.startsWith('video/')
      ? 'videos'
      : file.type.startsWith('audio/')
      ? 'audios'
      : 'files';

    // Crear el nombre del archivo dentro de Firebase Storage
    const fileName = `${fileType}/${chatId}/${fileId}_${file.name}`;
    const fileRef = ref(storage, fileName); 

    // Subir el archivo a Firebase Storage
    try {
      console.log("Subiendo archivo a Firebase...");
      await uploadBytes(fileRef, file);
      console.log("Archivo subido con éxito");
    } catch (error) {
      console.error("Error al subir archivo:", error);
      throw error;
    }

    // Obtener la URL de descarga después de subir el archivo
    const downloadURL = await getDownloadURL(fileRef);

    // Retornar la URL de descarga, el tipo de archivo y la referencia del archivo
    return { url: downloadURL, fileType, fileRef: fileRef.fullPath };
  } catch (error) {
    console.error('Error al subir archivo a Firebase Storage:', error);
    throw error;
  }
};

// /**
//  * Formatea los datos del chat.
//  * @param {string} chatId - ID del chat.
//  * @param {Object} chatData - Datos del chat.
//  * @param {Array} participants - Lista de participantes.
//  * @param {Object} otherParticipant - Información del otro participante.
//  * @returns {Object} - Datos formateados del chat.
//  */
// const formatChatData = (chatId, chatData, participants, otherParticipant) => {
//   const formattedTimestamp = chatData.lastMessage?.timestamp
//     ? formatTimestamp(
//         chatData.lastMessage.timestamp.seconds * 1000 +
//         Math.floor(chatData.lastMessage.timestamp.nanoseconds / 1000000),
//       )
//     : '';

//   return {
//     chatId,
//     createdAt: chatData.createdAt.toDate().toISOString(),
//     image: otherParticipant?.imageUrl || chatData.image,
//     lastMessage: {
//       ...chatData.lastMessage,
//       timestamp: formattedTimestamp,
//     },
//     type: chatData.type,
//     name: otherParticipant?.name || chatData.name,
//     blocked: chatData.blocked,
//     participants,
//     loggedUserId: participants.find(p => p.userId !== otherParticipant.userId)?.userId || '',
//   };
// };

// export const getUserByUserId = async userId => {
//   try {
//     if (!userId) throw new Error('El userId es obligatorio');

//     const userQuerySnapshot = await firestore()
//       .collection('Users')
//       .where('userId', '==', userId)
//       .limit(1)
//       .get();

//     if (userQuerySnapshot.empty) return null;

//     const userDoc = userQuerySnapshot.docs[0];
//     console.log("USER DATA ",userDoc.data());
//     console.log("REF ID ", userDoc.id);
//     return {
//       data: userDoc.data(),
//       ref: userDoc.id,
//     };
//   } catch (error) {
//     console.error('Error al obtener el usuario:', error.message);
//     return null;
//   }
// };

// /**
//  * Elimina un mensaje del chat y actualiza el último mensaje en el chat si es necesario.
//  * @param {string} chatId - ID del chat.
//  * @param {string} messageId - ID del mensaje a eliminar.
//  */
// export const deleteMessageFromChat = async (chatId, messageId) => {
//   try {
//     const messageRef = firestore()
//       .collection('chats')
//       .doc(chatId)
//       .collection('messages')
//       .doc(messageId);

//     // Obtener la referencia y los datos del mensaje
//     const messageDoc = await messageRef.get();
//     const messageData = messageDoc.data();

//     if (messageData && messageData.fileRef) {
//       // Eliminar el archivo si existe
//       await deleteFileFromGsUrl(messageData.fileRef);
//     }

//     // Actualizar el mensaje a "Mensaje eliminado"
//     await messageRef.update({
//       deleted: true,
//       text: 'Mensaje eliminado',
//       file: null,
//       fileRef: null,
//       fileType: null,
//     });

//     console.log('Mensaje eliminado correctamente');

//     // Verificar si el mensaje eliminado es el último mensaje del chat
//     const messagesQuerySnapshot = await firestore()
//       .collection('chats')
//       .doc(chatId)
//       .collection('messages')
//       .orderBy('timestamp', 'desc')
//       .limit(1)
//       .get();

//     const lastMessageDoc = messagesQuerySnapshot.docs[0];

//     if (lastMessageDoc) {
//       // Si hay un mensaje restante, actualizar el lastMessage del chat
//       const lastMessageData = lastMessageDoc.data();
//       await firestore()
//         .collection('chats')
//         .doc(chatId)
//         .update({
//           lastMessage: {
//             message: lastMessageData.text,
//             timestamp: lastMessageData.timestamp,
//             senderId: lastMessageData.senderId,
//           },
//         });

//       console.log('Último mensaje actualizado');
//     } else {
//       // Si no hay más mensajes, limpiar el campo lastMessage
//       await firestore()
//         .collection('chats')
//         .doc(chatId)
//         .update({
//           lastMessage: null,
//         });

//       console.log('No hay más mensajes, campo lastMessage limpiado');
//     }
//   } catch (error) {
//     console.error('Error al eliminar el mensaje:', error);
//     throw error;
//   }
// };

// /**
//  * Elimina un archivo de Firebase Storage dado su URL de tipo `gs://`
//  * @param {string} gsUrl - URL del archivo en formato `gs://`
//  * @returns {Promise<void>} - Promesa que se resuelve cuando el archivo se elimina exitosamente
//  */
// const deleteFileFromGsUrl = async (gsUrl) => {
//   if (!gsUrl.startsWith("gs://")) {
//     throw new Error("URL inválida, debe comenzar con 'gs://'");
//   }

//   try {
//     const bucketName = gsUrl.split("/")[2];
//     const filePath = gsUrl.replace(`gs://${bucketName}/`, "");

//     const fileRef = storage().ref(filePath);

//     const fileExists = await fileRef.getDownloadURL().catch(() => null);
//     if (!fileExists) {
//       console.log("El archivo ya no existe en Firebase Storage.");
//       return;
//     }

//     await fileRef.delete();
//     console.log("Archivo eliminado exitosamente:", filePath);
//   } catch (error) {
//     console.error("Error al eliminar el archivo:", error);
//     throw error;
//   }
// };

// export const getChatDataFromChannel = async (channel) => {

//   const chatDocRef = firestore().collection('chats').doc(channel.chat_id);
//   const chatDoc = await chatDocRef.get();

//   if (!chatDoc.exists) {
//     // Si el chat no existe, crear un chat vacío
//     const chatData = await createEmptyChat(channel.name, channel.channel_image, 'CH');

//     await updateChannel(
//       channel.id,
//       channel.name,
//       channel.description,
//       channel.max_users,
//       channel.price,
//       channel.has_chat,
//       channel.channel_category,
//       channel.is_private,
//       channel.free_sessions,
//       chatData.chatId,
//     );

//     return chatData;
//   } else {

//     const chatData = chatDoc.data();

//     const formattedChat = {
//       chatId: chatDoc.id,
//       name: chatData.name || channel.name,
//       imageUrl: chatData.image || channel.channel_image || '/user.png',
//       type: chatData.type || 'CH',
//       lastMessage: null,
//       createdAt: chatData.createdAt?.toDate().toISOString() || new Date().toISOString(),
//       participants: [],
//       loggedUserId: '',
//     };

//     return formattedChat; // Devolver el chat con placeholders
//   }
// };

// export const createEmptyChat = async (chatName, chatImage, chatType) => {
//   try {

//     // Crear un nuevo chat vacío
//     const newChatRef = firestore().collection('chats').doc();

//     const chatData = {
//       chatId: newChatRef.id,
//       createdAt: firestore.Timestamp.fromDate(new Date()),
//       image: chatImage || null,
//       lastMessage: null,
//       name: chatName,
//       type: chatType,
//       blocked: false,
//     };

//     await newChatRef.set(chatData);

//     const participantsCollection = newChatRef.collection('participants');
//     const messagesCollection = newChatRef.collection('messages');

//     console.log('Nuevo chat vacío creado correctamente:', newChatRef.get().data());
//     return newChatRef.get().data();
//   } catch (error) {
//     console.error('Error al crear el chat vacío:', error);
//     throw error;
//   }
// };

// /**
//  * Añade participantes a un chat en Firestore.
//  * @param {string} chatDocRefId - ID del documento del chat en Firestore.
//  * @param {string} channelId - ID del canal para obtener la lista de miembros desde la base de datos externa.
//  */
// export const addParticipantsToChat = async (chatDocRefId, channelId) => {
//   try {
//     if (!chatDocRefId || !channelId) {
//       throw new Error('El ID del chat y el ID del canal son obligatorios.');
//     }

//     const response = await getMembersOfChannel(channelId);
//     const externalUsers = response.data.users;
//     const usersSnapshot = await firestore().collection('Users').get();

//     const firestoreUsers = usersSnapshot.docs.map((doc) => ({
//       ref: doc.id,
//       ...doc.data(),
//     }));

//     const matchingUsers = firestoreUsers.filter((firestoreUser) =>
//       externalUsers.some((externalUser) => externalUser.id === firestoreUser.userId)
//     );

//     if (matchingUsers.length === 0) {
//       console.log('No hay usuarios para agregar como participantes.');
//       return;
//     }

//     const chatDocRef = firestore().collection('chats').doc(chatDocRefId);
//     const participantsRef = chatDocRef.collection('participants');

//     const batch = firestore().batch();

//     matchingUsers.forEach((user) => {
//       const participantDocRef = participantsRef.doc(user.ref);

//       batch.set(participantDocRef, {
//         imageUrl: user.avatarURL || null,
//         name: user.name || '',
//         userId: user.userId || '',
//         email: '',
//       });
//     });

//     await batch.commit();

//     console.log('Participantes añadidos correctamente al chat.');
//   } catch (error) {
//     console.error('Error al añadir participantes al chat:', error);
//   }
// };

/**
* Escucha cambios en la colección 'Users' en Firestore y actualiza la lista de usuarios.
* @param {Function} callback Función que se llama con la lista de usuarios actualizada.
* @returns {Function} Función para cancelar la suscripción al listener.
*/
export const getAllUsers = callback => {
 try {

    const chatsRef = collection(db, "Users");
    const q = query(chatsRef)

    const unsubscribe = onSnapshot(q, async (usersSnapshot) => {
        const users = usersSnapshot.docs.map((doc) => doc.data());
        callback(users);
      });

    return unsubscribe;
  } catch (error) {
    console.error('Error al escuchar cambios en los usuarios:', error);
    throw new Error('No se pudieron escuchar los cambios en los usuarios');
  }
};

export const notifyNewMessage = async (channelId, authorId, message) => {
  try {
    axios
      .post(`https://admin.growpsy.com/send_push_to_user`, {
        user_id: authorId,
        channel_id: channelId,
        message: message,
      })
      .then(
        res => {},
        error => console.log(error),
      );
  } catch (error) {
    console.log('error ', error);
  }
};
