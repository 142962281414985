import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
//import Upload from 'react-native-background-upload';
//import DeviceInfo from 'react-native-device-info';

const axiosInstance = axios.create({baseURL: 'https://node.growpsy.com/'});
const baseUrl = "https://admin.growpsy.com";


/**
 * Recoge el chat del canal
 * */
export const getChatChannel = (idChannel, userId) => {
  return new Promise((resolve, reject) => {
    axiosInstance.get(`channels/${idChannel}/${userId}`).then(res => {
      resolve(res);
    }).catch(err => {
      reject(err);
    })
  })
}

/**
 * Recoge otra pagina del chat
 * */
export const getChatChannelPagination = (idChannel, lastMessageId) => {
  return new Promise((resolve, reject) => {
    axiosInstance.get(`channels/${idChannel}/next-page/${lastMessageId}`).then(res => {
      resolve(res);
    }).catch(err => {
      reject(err);
    })
  })
}

const responseMymeType = (route, type) => {
  let customMime = "";
  if (type === "AUDIO") {
    customMime = route.includes('.wav') ? 'audio/wav' : 'audio/mp4'
  }
  return customMime;
}

/**
 * Recoge todos los mensajes de un chat
 * */
export const sendMessage = async (data, route, channelId, userId, type, message) => {
  return new Promise((resolve, reject) => {
    /*if (route !== undefined) {
      const options = {
        url: 'https://node.growpsy.com/messages',
        path: route,
        parameters: {'channelId': String(channelId), 'userId': String(userId), message: message, customMimeType: responseMymeType(route, type), appVersion: 'web'},
        method: 'POST',
        type: 'multipart',
        maxRetries: 2, // set retry count (Android only). Default 2
        headers: {
          'content-type': 'multipart/form-data', // Customize content-type
        },
        notification: {
          enabled: true,
          autoClear: true,
        },
        field: 'file',
        useUtf8Charset: true
      }
      Upload.startUpload(options).then(res => {
        resolve(res);
      }).catch(err => {
        reject(err);
      })

    } else {*/
    console.log('DATA', data)
      fetch('https://node.growpsy.com/messages', {
        method: 'post',
        body: data,
        headers: {'Access-Control-Allow-Origin': '*', "contentType": "multipart/form-date" }
      }).then(res => {
        resolve(res);
      }).catch(err => {
        reject(err);
      })
    //}
  })
}

/**
 * Elimina un mensaje
 * */
export const deleteMessage = async (idChannel, idUser, idMessage) => {
  const data = {
    "channelId": idChannel,
    "userId": idUser,
    "messageId": idMessage
  }
  return new Promise((resolve, reject) => {
    axiosInstance.delete(`messages/${idMessage}/${idUser}`, data).then(res => {
      resolve(res);
    }).catch(err => {
      reject(err);
    })
  })
}

/**
 * Comprobar si me puedo unir a una sesion
 * */
export const joinSession = async (idUser, idSession) => {
  const data = {
    userId: idUser,
    videoSessionId:idSession
  }

  return new Promise((resolve, reject) => {
    axiosInstance.post('video-sessions/join-session', data).then(res => {
      resolve(res);
    }).catch(err => {
      console.log("error join session", err)
      reject(err);
    })
  })
}

/**
 * Crea un chat privado
 * */
export const createPrivateChat = async (targetId) => {
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': await AsyncStorage.getItem('token')
  }
  const data = {
    target_id: targetId
  };
  return new Promise((resolve, reject) => {
    axios.post(`${baseUrl}/create_private_conversation.json`, data, {headers})
    .then(res => {
      resolve(res.data);
    })
    .catch(err => {
      reject(err);
    })
  })
}



