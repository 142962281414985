import React, { useEffect, useState, useRef } from "react";
import {
  StyleSheet,
  SafeAreaView,
  ScrollView,
  View,
  Text,
  Dimensions,
  createContext,
  TouchableOpacity,
  FlatList,
  Image
} from "react-native";
import { 
  getChatsFromUserId,
  getAllUsers,
  getChatDataFromId,
 } from "../services/firestore.service";
 import {createPrivateChat} from '../services/chat.service';
import { Searcher } from "../components/searcher.component";
import { Member } from "../components/member.component";
import { Loader } from "../components/loader.component";
import { noPushNotifications } from "../services/data.service";
import * as Colors from "../styles/Colors";
import { normalizeString } from "../utils/formatters";
import { useParams, useLocation, Link, useNavigate } from "react-router-dom";
import {ReactComponent as Close} from "../assets/close.svg";



const { width } = Dimensions.get("window");

const PrivateChatsScreen = (props) => {
  const [fbChat, setFbChat] = useState([]);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(true); 
  const [isSearchingUsers, setSearchingUsers] = useState(false);

  const [users, setUsers] = useState([]);
  const [userSearch, setUserSearch] = useState('');
  const [filteredUsers, setFilteredUsers] = useState([]);

  const fbChatBackup = useRef([]);
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = getChatsFromUserId((chats) => {
      if (chats !== undefined && chats !== null) {
        setFbChat(chats);
        fbChatBackup.current = chats;
        setLoading(false);
      } else {
        console.error("Received undefined or null chats");
        setFbChat([]);
        fbChatBackup.current = [];
        setLoading(false);
      }
    });
  
    return () => {
      if (unsubscribe) {
        try {
          unsubscribe();
        } catch (error) {
          console.log(error);
        }
      }
    };
  }, []);

  /* Filtra las conversaciones privadas */
  const searchChats = (value) => {
    setSearch(value);
    if (value !== "") {
      const filteredChats = fbChatBackup.current.filter((chat) =>
        normalizeString(chat.name).includes(normalizeString(value))
      );
      setFbChat(filteredChats);
    } else {
      setFbChat(fbChatBackup.current); 
    }
  };

  const searchUsers = (value) => {
    setSearch(value);
    if (value !== '' && users !== null) {
      let filteredUsers = users.filter(user => {
        const normalizeUserName = normalizeString(user.name).toLowerCase();
        const normalizeSearchValue = normalizeString(value).toLowerCase();
        return normalizeUserName.includes(normalizeSearchValue);
      })
      setFilteredUsers(filteredUsers);
    }
    else {
      setFilteredUsers(users);
    }
  }

  const searchingUsers = async () => {
    setSearch("");
    if (!isSearchingUsers) {
      try {
        const unsubscribe = getAllUsers(usersList => {
          setUsers(usersList);
          setFilteredUsers(usersList)
        });
  
        setSearchingUsers(true);
        return () => unsubscribe();
      } catch (error) {
        console.error('Error al cargar usuarios:', error);
      }
    } else {
      setFbChat(fbChatBackup.current); 
      setSearchingUsers(false);
    }
  };

  const renderUserList = () => {        
    return filteredUsers.map((user) => {
      return (
        <TouchableOpacity
        style={styles.userItem}
        onPress={() => {
          createChatAndNavigate(user);
        }}>
          <Image
            source={{
              uri: !user.avatarURL.startsWith('http')
                ? `https://admin.growpsy.com/${user.avatarURL}`
                : user.avatarURL,
            }}
            style={styles.avatar}
          />
        <Text style={styles.userName}>{user.name}</Text>
      </TouchableOpacity>
      )
    })
  }

const createChatAndNavigate = async selectedUser => {
  try {
    setLoading(true)
    const {id} = await createPrivateChat(selectedUser.userId);
    const chatData = await getChatDataFromId(id);

    if (chatData) {
      navigate(`/chats/${chatData.dbChatId}/${chatData.type}`, {
        state: { chatData: chatData },
      })
    }
  } catch (error) {
    console.error('Error al crear o encontrar el chat:', error);
    setLoading(false)

  }
};

  return (
    <SafeAreaView style={styles.safeArea}>
      <ScrollView
        showsVerticalScrollIndicator={false}
        keyboardShouldPersistTaps={"always"}
      >
        <View style={styles.container}>
          <Searcher
            placeholder={isSearchingUsers ? "Buscar usuario": "Busca tus chats"}
            value={search}
            getValue={(value) => { isSearchingUsers ? searchUsers(value) : searchChats(value)}}
          />

          { loading ? (
            <Loader style={{ flex: 1, height: 500 }} />
          ) : 
          (isSearchingUsers ?
            filteredUsers.length > 0 ? 
              renderUserList()
              : (
                <Text>No se han encontrado usuarios</Text>
              )
            :
            fbChat.length > 0 ? (
              fbChat.map((chat) => {
                return (
                  <Link
                    key={chat.chatId}
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      textDecoration: "none",
                    }}
                    to={`/chats/${chat.dbChatId}/${chat.type}`}
                    state={{ idChat: chat.dbChatId }}
                  >
                    <Member
                      image={chat.image}
                      name={chat.name}
                      type={chat.lastMessage.message}
                      buttonTime={false}
                      newMessages={null}                
                      // lastMessageTime={chat.lastMessage.timestamp}
                      pressAll={() => {
                        navigate(`/chats/${chat.dbChatId}/${chat.type}`, {
                          state: { chatData: chat },
                        });
                      }}
                    />
                  </Link>
                );
              })
            ) : (
              <Text>No tienes chats activos</Text>
            )
          )}
        </View>
      </ScrollView>

      { !loading && <TouchableOpacity 
        style={
          isSearchingUsers ? 
            styles.floatingButton
            :
            [styles.floatingButton, { transform: [{ rotate: '45deg' }] }]
        } 
        onPress={searchingUsers}>
          <Close style={styles.floatingButtonText} />
      </TouchableOpacity>}
    </SafeAreaView>
  );
};

export default PrivateChatsScreen;

const styles = StyleSheet.create({
  safeArea: {
    flex: 1,
    width: "100%",
    marginRight: "auto",
    marginLeft: "auto",
    backgroundColor: Colors.default.COLORS.background_light,
    paddingTop: 50,
    paddingBottom: 70,
  },
  container: {
    flex: 1,
    alignItems: "center",
  },  
  floatingButton: {
    position: 'fixed', 
    right: '2%',
    bottom: '5%',
    backgroundColor: Colors.default.COLORS.primary,
    width: 60,
    height: 60,
    borderRadius: 30,
    justifyContent: 'center',
    alignItems: 'center',
    elevation: 5,
  },
  floatingButtonText: {
    color: 'white',
    width: 24,
    height: 24,
  },
  userItem: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 20,
    width: '90%',
    paddingVertical: 10,
    borderBottomWidth: 1,
    borderBottomColor: '#e1e1e1'
    
  },
  avatar: {
    width: 50,
    height: 50,
    borderRadius: 100,
    marginRight: 10
  },
  userName: {
    fontSize: 16,
  },
});
